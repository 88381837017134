// 主应用、微应用公共状态
import { initGlobalState } from 'qiankun'

import store from '@/store'

const initialState = {
  isFoldSlider: store.state.isFoldSlider,
  isHideLeftMenu: store.state.isHideLeftMenu,
  routeMeta: store.state.routeMeta,
  userInfo: store.state.userInfo,
  appList: store.state.appList, // 系统列表数据
  allLocalApp: store.state.allLocalApp, // 所有应用下的系统集合
  appschemeMenu: store.state.appschemeMenu, // 应用方案下的菜单集合
  processInstanceId: '',// 运维：流程实例ID
  formTypeName:'', //运维：表单类型名称
}

// 初始化 state
const actions = initGlobalState(initialState)
actions.onGlobalStateChange((state, prev) => {
  //监听公共状态的变化
  if (state.isHideLeftMenu !== prev.isHideLeftMenu) {
    store.commit('SET_LEFT_MENU_SHOW_STATUS', state.isHideLeftMenu)
  }
  if (state.routeMeta !== prev.routeMeta) {
    store.commit('SET_ROUTE_META', state.routeMeta)
  }
})

export default actions
