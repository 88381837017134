/**
 * @CreateTime 2020-08-23
 * @description 配置：微前端-主应用基座
 * @document https://qiankun.umijs.org/zh/
 * **/

import {
  registerMicroApps,
  start,
  addGlobalUncaughtErrorHandler,
  runAfterFirstMounted,
  setDefaultMountApp,
  initGlobalState,
} from "qiankun";
import NProgress from "nprogress";
import { Message, MessageBox } from "element-ui";
import apps from "./apps";
import lifeCycles from "./lifeCycles";

/**
 * @description 设置主应用启动后默认进入的微应用
 * **/
// setDefaultMountApp(apps[0].activeRule);

/**
 * @description 在主应用中注册微应用 基于路由配置
 * @param {Array} apps 注册微应用
 * @param {Object} lifeCycles 全局的微应用生命周期钩子
 * **/
registerMicroApps(apps, lifeCycles);

/**
 * @description 第一个微应用mount后需要调用的方法
 * **/
runAfterFirstMounted(() => {
  console.log(`runAfterFirstMounted`);
});

/**
 * @description 添加全局的未捕获异常处理器
 * @param {Function} callback
 * **/
addGlobalUncaughtErrorHandler((event) => {
  NProgress.done();
  const { message } = event?.error || {};

  if (message && message.includes("died in status LOADING_SOURCE_CODE")) {
    // MessageBox.confirm('请检查微应用是否可运行', '微应用加载失败', {
    //   confirmButtonText: '刷新应用',
    //   cancelButtonText: '取消',
    //   type: 'error',
    //   showClose: false,
    //   closeOnClickModal: false
    // }).then(() => {
    //   window.location.reload();
    // }).catch(() => {

    // });
    Message.error("微应用加载失败，请检查微应用是否可运行");
  } else if (message && message.includes("died in status NOT_MOUNTED")) {
    Message.error("微应用挂载DOM不存在，请检查主应用是否存在挂载点$el");
  }
});

export default start;
