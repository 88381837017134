<!-- 头部菜单栏 -->
<template>
  <div class="flex">
    <!--切换系统-->
    <div class="mr10 flex-center-item">
      <el-dropdown @command="handleCommandSystem" placement="bottom" style="display: flex; align-items: center">
        <!-- <img src="@/assets/img/app.png" class="layout-icon-img" /> -->
        <span class="font-bold text-theme font12 cursor layout-icon-sys">
          <i class="el-icon-caret-bottom"></i>
          {{ curAppData2.name }}
        </span>

        <el-dropdown-menu slot="dropdown" class="custom-el-dropdown-menu">
          <el-dropdown-item v-for="item in localApp" :key="item.id" :command="item" :class="{ active: curAppData2.name === item.name }" class="text-center">
            <div class="flex-c">
              <img style="width: 22px; height: 22px; overflow: hidden; margin-right: 10px" :src="item.icon" />
              <span style="font-weight: 700">{{ item.name }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!--切换应用-->
    <div class="mr20 flex-center-item">
      <el-select v-model="appCode" filterable placeholder="请选择应用" @change="changeApplication">
        <el-option label="请选择应用" value=" " disabled><i class="el-icon-thumb"></i>请选择应用</el-option>
        <el-option v-for="item in applicationList" :key="item.id" :label="item.appName" :value="item.appCode">
          <i class="el-icon-position"></i>
          {{ item.appName }}
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { child_app_path } from '@/utils/constant'
export default {
  data() {
    return {
      appCode: '',
    }
  },
  computed: {
    ...mapState(['curAppData', 'curAppData2', 'localApp', 'appList', 'application', 'applicationList', 'allLocalApp']),
  },
  created() {
    this.appCode = this.$route.query.p_app_code
  },
  methods: {
    // 切换应用下的系统
    handleCommandSystem(item) {
      if (!this.application.appCode) {
        this.$message.warning('请先选择具体应用')
        return
      } else {
        const { appSchemeCode, c_id } = this.$route.query
        // 当系统是是开发系统或是已存在方案code时跳转到方案里面，否则跳转到各个系统的方案列表
        if (!!appSchemeCode && appSchemeCode != 'DEFAULT' && item.code !== '/micro-dev') {
          this.$store.commit('SET_LEFT_MENU_SHOW_STATUS', false)
          this.$router.push({
            path: item.code,
            query: {
              p_id: this.curAppData.id,
              c_id: item.id,
              p_app_code: this.application.appCode,
              appSchemeCode,
            },
          })
        } else {
          this.$router.push({
            path: item.code + child_app_path,
            query: {
              p_id: this.curAppData.id,
              c_id: item.id,
              p_app_code: this.application.appCode,
            },
          })
        }
        this.$store.dispatch('GET_CUR_APP_INFO', this.allLocalApp[this.application.appCode])
      }
    },
    changeApplication(val) {
      let applicationObj = _.find(this.applicationList, ['appCode', val])
      this.$store.commit('SET_APPLICATION', applicationObj)
      let curAppSystem = this.allLocalApp[val]
      if (curAppSystem?.length) {
        let curSystem = {}
        // 判断该应用是否有当前系统权限
        let isExit = _.findIndex(curAppSystem, ['code', this.curAppData2?.code])
        if (isExit == -1) {
          this.$message.error('当前应用没有当前系统权限')
          window.location.href = window.location.origin + '/'
        } else {
          curSystem = this.curAppData2
          this.$router.push({
            path: curSystem.code + child_app_path,
            query: {
              p_id: this.curAppData.id,
              c_id: curSystem.id,
              p_app_code: val,
            },
          })
        }
      } else {
        this.$router.push('/')
      }

      this.$store.commit('SET_CUR_APP2', {})
    },
    // 切换应用
    handleCommandApps(item) {
      const { appCode } = item
      this.$store.commit('SET_APPLICATION', item)
      let curAppSystem = this.allLocalApp[appCode]
      if (curAppSystem?.length) {
        let curSystem = {}
        // 判断该应用是否有当前系统权限
        let isExit = _.findIndex(curAppSystem, ['code', this.curAppData2?.code])
        curSystem = isExit ? this.curAppData2 : curAppSystem[0]
        this.$router.push({
          path: curSystem.code + child_app_path,
          query: {
            p_id: this.curAppData.id,
            c_id: curSystem.id,
            p_app_code: appCode,
          },
        })
      }

      this.$store.commit('SET_CUR_APP2', {})
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-el-dropdown-menu {
  min-width: 100px;

  .el-dropdown-menu__item.active {
    color: $theme-color;
  }

  .el-dropdown-menu__item {
    line-height: 35px !important;
  }
}
.flex-center-item {
  display: flex;
  align-items: center;
  /deep/input {
    border-radius: 15px !important;
  }
}

.layout-icon-sys {
  box-sizing: content-box;
  padding: 3px;
  height: 47px;
  line-height: 47px;
  &:hover {
    background-color: #f0f0f0;
  }
}
</style>
