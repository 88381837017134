<!--  -->
<template>
  <el-dialog title="文件夹管理" :visible="show" :close-on-click-modal="false" @close="close" width="500px">
    <div class="mb10">
      <el-button type="primary" @click="handleAdd({})">新增</el-button>
    </div>
    <el-tree :data="folderList" node-key="id" default-expand-all :expand-on-click-node="false" :props="defaultProps" style="height: 300px" class="overflow-auto">
      <span slot-scope="{ node, data }" class="custom-tree-node">
        <span class="font12">
          <i class="el-icon-folder-opened font14 text-warning"></i>
          <span class="ml5">{{ node.label }}</span>
        </span>
        <span>
          <el-button type="text" size="mini" @click.native="handleAdd(data)" > 新增 </el-button>
          <el-button type="text" size="mini" @click.native="handleEdit(data)"> 编辑 </el-button>
          <el-button type="text" size="mini" @click.native="handleDelete(data)"> 删除 </el-button>
        </span>
      </span>
    </el-tree>
    <span slot="footer">
      <el-button type="primary" plain @click="close">关闭</el-button>
    </span>
    
    <el-dialog :title="operType == 'add' ? '新增文件夹' : '编辑文件夹'" :visible.sync="showAdd" @close="showAdd = false" :close-on-click-modal="false" append-to-body width="400px">
      <el-form :model="addForm" class="mr20">
        <el-form-item label="文件夹名称" label-width="100px">
          <el-input v-model="addForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <div class="text-center">
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="showAdd = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </el-dialog>
</template>

<script>
import Api from '@/api/index.js'
export default {
  props: {
    show: { type: Boolean, default: false },
    folderList: {type: Array, default: () => []}
  },
  data() {
    return {
      operType: 'add',
      showAdd: false,
      addForm: {},
      defaultProps: {
        children: 'chirldren',
        label: 'name',
      },
      curRow: {},
    }
  },
  methods: {
    async save() {
      if(!this.addForm.name) {
        return this.$message.error('文件夹名称不能为空');
      }
      let param = {
        type: 1,
        parentId: this.curRow.id || 0,
        ...this.addForm
      }
      if(this.operType == 'add') {
        await Api.addCollect(param)
      }else {
        await Api.updateCollect(param)
      }
     
      this.$message.success('保存成功！')
      this.showAdd = false
      this.$emit('updateFolder')
    },
    async handleDelete(data) {
      await Api.deleteCollect({ id: data.id })
      this.$message.success('删除成功！')
      this.showPopover = false
      this.$emit('updateFolder')
      this.$emit('delFolder')
    },
    handleAdd(data) {
      this.addForm = {}
      this.curRow = data || {}
      this.operType = 'add'
      this.showAdd = true
    },
    handleEdit(data) {
      this.addForm = {...data}
      this.curRow = data || {}
      this.operType = 'edit'
      this.showAdd = true
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
