<!-- 用户信息 -->
<template>
  <el-popover placement="bottom" width="250" trigger="hover">
    <el-descriptions class="margin-top border-bottom-line pb10" :column="1">
      <el-descriptions-item label="租户名称">
        {{ userInfo.details.enterpriseAccount.name }}
      </el-descriptions-item>
      <el-descriptions-item label="租户账号">
        {{ userInfo.details.enterpriseAccount.account }}
      </el-descriptions-item>
      <el-descriptions-item label="租户邮箱">
        {{ userInfo.details.enterpriseAccount.email }}
      </el-descriptions-item>
      <el-descriptions-item label="用户昵称">
        {{ userInfo.nickname }}
      </el-descriptions-item>
      <el-descriptions-item label="登录账号">
        {{ userInfo.sub }}
      </el-descriptions-item>
      <el-descriptions-item label="用户邮箱">
        {{ userInfo.email }}
      </el-descriptions-item>
    </el-descriptions>
    <div class="mt10 text-center">
      <el-button type="primary" plain @click="handleUpdatePassward()">修改密码</el-button>
      <el-button type="primary" plain @click="handleLogout()">退出登录</el-button>
    </div>
    <div class="el-dropdown-link cursor flex-c" slot="reference">
      <el-avatar icon="el-icon-user-solid" size="medium" :src="userInfo.picture"></el-avatar>
      <div class="name ml5">{{ userInfo.nickname }}</div>
      <i class="el-icon-caret-bottom" style="color: #aaa;font-size: 16px;"></i>
    </div>
    <w-update-password :userInfo="userInfo" :show.sync="showPassword" @updatePass="updatePassSuccess" />
  </el-popover>

</template>

<script>
import WUpdatePassword from "./updatePassword";
import http from "@/utils/axios";
export default {
  props: {
    userInfo: { type: Object, default: () => {} }
  },
  components: { WUpdatePassword },
  data() {
    return {
      showPassword: false
    };
  },

  methods: {
    // 修改密码成功后退出登录
    updatePassSuccess() {
      this.showPassword = false;
      http.post("/logout", null, { baseURL: "" });
    },
    handleLogout() {
      this.$confirm("确定退出登录吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        http.post("/logout", null, { baseURL: "" });
      });
    },
    handleUpdatePassward() {
      this.showPassword = true;
    }
  }
};
</script>
<style lang="scss" scoped></style>
