/**
 * @CreateTime 2020-08-23
 * @description 注册微应用
 * **/
import { child_el } from "@/utils/constant";
import router from '../router'
import store from '../store'
const $el = `#${child_el}`; // 微应用挂载点
const isDevEnv = process.env.NODE_ENV === "development"; // 判断是否是开发环境

/**
 * @param name {String}  必选，微应用的名称，微应用之间必须确保唯一
 * @param entry {String | { scripts?: String[]; styles?: String[]; html?: String }} 必选，微应用的 entry 地址
 * @param container {String | HTMLElement} 必选，微应用的容器节点的选择器或者 Element 实例
 * @param activeRule {String | (location: Location) => Boolean | Array<String | (location: Location) => Boolean>} 必选，微应用的激活规则(值与企业开发后台配置系统的系统编码保持一致)
 * @param props {Object} 可选，主应用需要传递给微应用的数据
 * **/
const APP_MICRO = [
  {
    name: "application-app",
    entry: isDevEnv ? "http://localhost:8088/" : "/application-app/",
    container: $el,
    activeRule: "/micro-app",
  },
  {
    name: "dev-app",
    entry: isDevEnv ? "http://localhost:8089/" : "/dev-app/", // 默认加载这个html，解析里面的js动态的执行（子应用必须支持跨域，内部使用的是 fetch）
    container: $el,
    activeRule: "/micro-dev",
    props: {},
  },
  {
    name: "auth-app",
    entry: isDevEnv ? "http://localhost:8090/" : "/auth-app/",
    container: $el,
    activeRule: "/micro-auth",
    props: {},
  },
  {
    name: "operation-app",
    entry: isDevEnv ? "http://localhost:8099/" : "/operation-app/",
    container: $el,
    activeRule: "/micro-operation",
    props: {},
  },
  {
    name: "devops-app",
    entry: isDevEnv ? "http://localhost:8098/" : "/devops-app/",
    container: $el,
    activeRule: "/micro-devops",
    props: {
      mainAppRouter: router,
      mainAppStore:store
    },
  },
  {
    name: "ipaas-console-app",
    entry: isDevEnv ? "http://localhost:8087/" : "/ipaas-console-app/",
    container: $el,
    activeRule: "/micro-ipaas-console",
    props: {},
  },
  {
    name: "config-app",
    entry: isDevEnv ? "http://localhost:8097/" : "/config-app/",
    container: $el,
    activeRule: "/micro-config",
    props: {},
  },
  {
    name: "workflow-app",
    entry: isDevEnv ? "http://localhost:8888/" : "/workflow-app/",
    container: $el,
    activeRule: "/micro-workflow",
    props: {},
    
  },
  {
    name: "reactapp",
    entry: isDevEnv ? "http://localhost:3000/" : "/reactapp/",
    container: $el,
    activeRule: "/cmp",
    props: {},
  },
  {
    name: "ops-app",
    entry: isDevEnv ? "http://localhost:8091/" : "/ops-app/",
    container: $el,
    activeRule: "/micro-ops",
    props: {
      mainAppRouter: router,
      mainAppStore:store
    },
  },
  {
    name: "order-app",
    entry: isDevEnv ? "http://localhost:8092/" : "/order-app/",
    container: $el,
    activeRule: "/micro-order",
    props: {},
  },
  {
    name: "bi-pro",
    entry: isDevEnv ? " http://10.10.44.173:3001/" : "/bi-pro/",
    container: $el,
    activeRule: "/bi",
    props: {},
  },
];

export default APP_MICRO;
