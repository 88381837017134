/**
 * @CreateTime 2020-08-23
 * @description 全局的微应用生命周期钩子
 * **/
 import NProgress from "nprogress";
 import 'nprogress/nprogress.css'

const LIFE_CYCLES = {
    // 微应用加载前
    beforeLoad: app => {
        console.info(`${app.name}:beforeLoad`)
        NProgress.start();
    },
    // 微应用挂载前
    beforeMount: app => {
        console.info(`${app.name}:beforeMount`)
    },
    // 微应用挂载后
    afterMount: app => {
        console.info(`${app.name}:afterMount`)
        NProgress.done();
    },
    // 微应用卸载前
    beforeUnmount: app => {
        console.info(`${app.name}:beforeUnmount`)
    },
    // 微应用卸载前
    afterUnmount: app => {
        console.info(`${app.name}:afterUnmount`)
    }
} 

export default LIFE_CYCLES