<!-- 收藏 -->
<template>
  <div class="mt5">
    <el-popover placement="bottom" width="340" trigger="click" v-model="showPopover">
      <i slot="reference" :class="isCollected ? 'el-icon-star-on text-theme' : 'el-icon-star-off text-font-color'" class="collect cursor" title="收藏/取消收藏" @click="hanldClick"></i>
      <div>
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane :label="isCollected ? '修改收藏' : '添加收藏'" name="first">
            <el-form label-width="70px">
              <el-form-item label="名称">
                <el-input v-model="form.name" style="width: 220px"></el-input>
              </el-form-item>
              <el-form-item label="文件夹">
                <el-select clearable ref="selectTree" v-model="form.parentId" placeholder="请选择" popper-class="popperTreeSelect">
                  <el-option value="0" label="无"></el-option>
                  <el-option :value="optionData.id" :label="optionData.name" class="tree-select__option">
                    <el-tree ref="tree" :data="folderList" default-expand-all node-key="id" class="tree-select__tree" :props="{ label: 'name', children: 'chirldren' }" @node-click="handleNodeClick">
                      <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span :class="{ link: data.id == form.parentId }">{{ node.label }}</span>
                      </span>
                    </el-tree>
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="flex-b ml20 pr20">
                <el-button type="primary" plain @click="handleMore">文件夹管理</el-button>
                <span>
                  <el-button type="primary" @click="save">确定</el-button>
                  <el-button @click="deleteCollect" v-if="form.id">移除</el-button>
                </span>
              </div>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="收藏列表" name="second">
            <div style="max-height: 300px" v-if="showPopover">
              <collect />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-popover>
    <folder :show.sync="showFolder" :folderList="folderList" @updateFolder="getAllFolder" @delFolder="getCurPathCollect" />
  </div>
</template>

<script>
import Folder from './folder'
import Api from '@/api/index.js'
import { mapState } from 'vuex'
import Collect from '@/views/home/collectList'
export default {
  data() {
    return {
      form: {},
      pathName: '',
      showFolder: false,
      isCollected: false,
      showPopover: false,
      folderList: [],
      optionData: {},
      activeName: 'first',
    }
  },
  components: { Folder, Collect },
  computed: {
    ...mapState(['breadcrumbData', 'appschemeMenu']),
  },
  watch: {
    $route: {
      handler(val) {
        this.form = {}
        this.getCurPathCollect()
      },
      deep: true,
      immediate: true,
    },
    showPopover(val) {
      if (val) {
        this.activeName = 'first'
      }
    },
  },
  created() {
    this.getAllFolder()
  },
  methods: {
    async hanldClick() {
      this.optionData = {}
      if (this.isCollected) {
        if (this.isCollected && this.form.parentId && this.form.parentId != '0') {
          // 正确回显文件夹
          this.getCurSelFolder(this.folderList)
        }
        return
      }
      this.$set(this.form, 'parentId', '0')
      this.pathName = ''
      let menuName = this.breadcrumbData[this.breadcrumbData.length - 1]?.name
      if (!menuName && _.startsWith(this.$route.path, '/micro-dev') && this.$route.query.appSchemeCode) {
        // 开发系统下开发菜单是单独的面包屑，数据不在主应用中，需要通过遍历菜单数据获取当前路由名称
        await this.getName(this.appschemeMenu)
      }
      this.$set(this.form, 'name', menuName || this.pathName || '企业开发平台')
    },
    // 获取所有文件夹
    async getAllFolder() {
      const { data } = await Api.getAllColletFolder({ type: 1 })
      this.folderList = data || []
    },
    getCurSelFolder(data) {
      for (let item of data) {
        if (item.id == this.form.parentId) {
          this.optionData = item
          return
        }
        if (item.chirldren?.length) {
          this.getCurSelFolder(item.chirldren)
        }
      }
    },
    getName(data) {
      for (let item of data) {
        if ('/micro-dev' + item.code == this.$route.path) {
          this.pathName = item.name
          return
        }
        if (item.children?.length) {
          this.getName(item.children)
        }
      }
    },
    handleMore() {
      this.showFolder = true
    },
    // 判断当前路径是否被收藏
    async getCurPathCollect() {
      const { data } = await Api.getCollectBypath({ favoriteTags: this.$route.fullPath })
      this.form = data ? data : {}
      this.isCollected = data ? true : false
    },
    async save() {
      this.form.type = 2
      this.form.favoriteTags = this.$route.fullPath
      let param = { ...this.form }
      param.parentId = param.parentId || 0
      if (this.isCollected) {
        await Api.updateCollect(param)
      } else {
        await Api.addCollect(param)
      }
      this.$message.success('收藏成功！')
      this.showPopover = false
      this.getCurPathCollect()
    },

    // 删除当前收藏
    async deleteCollect() {
      await Api.deleteCollect({ id: this.form.id })
      this.$message.success('删除成功！')
      this.showPopover = false
      this.getCurPathCollect()
    },
    handleNodeClick(data) {
      this.$set(this.form, 'parentId', data.id)
      this.$set(this.form, 'parentName', data.name)
      this.optionData = data
      this.$refs.selectTree.blur()
    },
   async handleTabClick(tab){
      if(tab.name == 'first') {
       await this.getCurPathCollect()
       this.hanldClick()
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.collect {
  font-size: 22px;
}
.el-select-dropdown__item:first-of-type {
  padding-left: 45px;
}
.tree-select__option {
  &.el-select-dropdown__item {
    height: auto;
    line-height: 1;
    padding: 0;
    background-color: #fff;
  }
}

.tree-select__tree {
  padding: 4px 20px;
  font-weight: 400;

  .el-tree-node.is-current > .el-tree-node__content {
    color: $theme-color;
    font-weight: 700;
  }
}
</style>
