<!-- 框架布局 -->
<template>
  <el-container class="container-main">
    <el-header style="height: 50px">
      <v-header></v-header>
    </el-header>

    <el-container>
      <el-aside
        style="transition: width 0.25s; border-right: 1px solid #e5e5e5;background: #fff;"
        :width="isFoldSlider ? '64px' : '210px'"
        v-if="!isHideLeftMenu"
      >
        <v-slider :leftMenuData="leftMenuData" />
      </el-aside>
      <el-main class="page-main">
        <v-breadcrumb v-if="!isHideLeftMenu" />
        <transition name="slide-fade" mode="out-in">
          <!-- <keep-alive> -->
            <router-view></router-view>
          <!-- </keep-alive> -->
        </transition>
        <div :id="childEl"></div>
      </el-main>
    </el-container>
    <advice />
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import VHeader from "./header.vue";
import VSlider from "./slider.vue";
import VBreadcrumb from "@/components/breadcrumb";
import qiankunStart from "@/micro";
import { child_el } from "@/utils/constant";
import advice from './components/advice'
export default {
  components: { VHeader, VSlider, VBreadcrumb, advice },
  data() {
    return {
      childEl:child_el
    };
  },
  computed: {
    ...mapState(["isFoldSlider", "leftMenuData", "isHideLeftMenu"]),
  },
  mounted() {
    qiankunStart({ strictStyleIsolation: true }); // 主应用加载完，才能挂载
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.el-header {
  padding: 0;
}
.container-main {
  .page-main {
    height: calc(100vh - 50px);
  }

  .el-aside::-webkit-scrollbar {
    display: none;
  }
}

.el-main {
  padding: 0;
  overflow: hidden;
}
</style>
