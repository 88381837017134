<template>
  <div class="edt-breadcrumb-box">
    <template v-if="breadcrumbData.length > 0">
      <!--default-->
      <el-breadcrumb separator="/" v-if="!$route.query.appSchemeCode">
        <template v-for="(item, index) in breadcrumbData">
          <el-breadcrumb-item 
          v-if="index === 0" 
          :key="item.id" 
          :to="{ path: item.code, query: $route.query }">
          <i class="el-icon-s-home" :title="item.name"></i>
        </el-breadcrumb-item>
          <el-breadcrumb-item v-else :key="item.code">{{ item.name }}</el-breadcrumb-item>
        </template>
      </el-breadcrumb>

      <!--app-->
      <el-breadcrumb separator="/" v-else>
        <el-breadcrumb-item :to="{ path: breadcrumbData[0].code + childAppPath, query: $route.query }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: breadcrumbData[0].code + childAppPath, query: $route.query }">{{ application.appName }}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: breadcrumbData[0].code + childAppPath, query: $route.query }">{{ appSchemeInfo.appSchemeName }}</el-breadcrumb-item>
        <el-breadcrumb-item v-for="item in breadcrumbData" :key="item.id">{{ item.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <!--back-->
    <div class="edt-router-back-btn" @click="goBack" v-else>
      <i class="el-icon-back"></i>
      <span style="margin-left: 3px" class="mr5">返回</span>
      <el-divider direction="vertical" v-if="$route.query.sub_title"></el-divider>
      <span class="ml5 text-theme">{{ $route.query.sub_title }}</span>
    </div>
  </div>
</template>

<script>
/**
 * 》点击对应层级的标签，跳转到对应的页面《
 * 1、default:默认根据菜单层级显示：首页/应用开发/应用管理/我的应用
 * 2、app:应用(开发、运维、工作流、集成)：首页/应用/应用方案/{子系统菜单层级显示}
 * **/
import { mapState } from 'vuex'
import Api from '@/api'
import { child_app_path } from '@/utils/constant'

export default {
  name: 'EDTBreadcrumb',
  props: {
    type: {
      type: String,
      default: 'default', // default:默认、app:应用
    },
  },
  data() {
    return {
      appSchemeInfo: {}, // 应用方案详情
      _appSchemeCode: undefined,
      childAppPath: child_app_path,
    }
  },
  computed: {
    ...mapState(['application', 'breadcrumbData']),
  },
  watch: {
    $route: {
      handler(val) {
        const { appSchemeCode } = val.query
        if (!!appSchemeCode & (this._appSchemeCode != appSchemeCode)) {
          this.getAppSchemeInfo(appSchemeCode)
        }
      },
      deep: true,
    },
  },
  mounted() {
    const { appSchemeCode } = this.$route.query
    if (!!appSchemeCode) {
      this.getAppSchemeInfo(appSchemeCode)
    }
  },
  methods: {
    // 查询应用解决方案详情
    getAppSchemeInfo(appSchemeCode) {
      if (appSchemeCode == 'DEFAULT') {
        return
      }
      Api.getAppSchemeInfo({ appSchemeCode }).then((res) => {
        this._appSchemeCode = appSchemeCode
        this.appSchemeInfo = res.data
      })
    },
    // 返回
    goBack() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
.edt-breadcrumb-box {
  background-color: #f7f9fb;
  height: 38px;
  padding-left: 20px;
  display: flex;
  align-items: center;

  .edt-router-back-btn {
    font-family: fangsong;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: #33bba7;
    }
  }
}
</style>
