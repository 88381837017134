<!-- 收藏列表 -->
<template>
  <div class="h100">
    <div v-if="collectTreeData.length" class="h100">
      <el-tree :data="collectTreeData" :props="defaultProps" class="h100 overflow-auto">
        <span slot-scope="{ node, data }" class="custom-tree-node">
          <span class="font12 flex-1 w-0" @click="toPage(data)">
            <i class="font14" :class="data.type == 1 ? 'el-icon-folder-opened text-warning' : 'el-icon-document text-theme'"></i>
            <span :class="data.type == 2?'collect-name' :''">{{ node.label }}</span>
          </span>
          <span class="collect-icon">
            <i class="el-icon-delete" @click="handleDelete(data)"></i>
          </span>
        </span>
      </el-tree>
    </div>
    <el-empty description="暂无数据" v-else></el-empty>
  </div>
</template>

<script>
import Api from '@/api/index.js'
export default {
  data() {
    return {
      loading: false,
      collectTreeData: [],
      defaultProps: {
        children: 'chirldren',
        label: 'name',
      },
    }
  },
  created() {
    this.getCollectData()
  },
  methods: {
    async getCollectData() {
      try {
        this.loading = true
        const { data } = await Api.queryCollect()
        this.collectTreeData = data || []
      } finally {
        this.loading = false
      }
    },
    async handleDelete(data) {
      await Api.deleteCollect({ id: data.id })
      this.$message.success('删除成功！')
      this.getCollectData()
    },
    toPage(data) {
      if (data.type == 2) {
        window.location.href = data.favoriteTags
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  font-size: 14px;
  padding-right: 8px;
  .collect-icon {
    color: #fff;
  }
  &:hover {
    background-color: #f2f4f7;
    border-bottom: none;
    .collect-icon {
      color: $theme-color;
    }
    .collect-name{
      color: $theme-color;
    }
  }
}
::v-deep .el-tree-node > .el-tree-node__children {
  overflow: visible !important;
}
</style>
