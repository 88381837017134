<!-- 左侧栏 -->
<template>
  <div class="slider">
    <el-menu class="app-custom-el-menu" :default-active="$route.path"  :collapse="isFoldSlider" :collapse-transition="false" :router="true" :unique-opened="true">
      <template v-for="item in menuList">
        <template v-if="item.path">
          <el-menu-item :key="item.code" :index="item.path" :route="{ path: item.path, query: $route.query }">
            <i class="iconfont mr5" v-html="item.icon" v-if="item.icon"></i>
            <span>{{ item.name }}</span>
          </el-menu-item>
        </template>
        <!-- 有次级菜单的,则展开子选项-->
        <el-submenu v-else :key="item.code" :index="item.code">
          <template #title>
            <i class="iconfont mr5" v-html="item.icon" v-if="item.icon"></i>
            <i class="iconfont mr5" v-else-if="!item.icon && isFoldSlider">&#xe607;</i>
            <span>{{ item.name }}</span>
          </template>
          <!-- 递归 -->
          <w-menutree :menuList="item.children"></w-menutree>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WMenutree from './menustree'
export default {
  props: {
    leftMenuData: { type: Array, default: () => [] },
  },
  components: { WMenutree },
  data() {
    return {
      menuList: [],
    }
  },
  computed: { ...mapState(['isFoldSlider']) },
  watch: {
    leftMenuData: {
      handler(val) {      
        this.menuList = this.menuTran(val)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    menuTran(list) {
      list &&
        list.forEach((item) => {
          if (item.children?.length) {
            if (item.children[0].type == 'resource') {
              let index = _.findIndex(item.children, o => o.isMenuPage)
              if(index !== -1) {
                item.path = item.children[index].code
              }
            } else {
              this.menuTran(item.children)
            }
          }
        })
      return list
    },
  },
}
</script>
<style scoped lang="scss">
.slider {
  background-color: #fff;
  .title {
    height: 50px;
    line-height: 50px;
    white-space: nowrap;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1.2px;
    font-family: cursive;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.app-custom-el-menu {
  height: calc(100vh - 50px);
  border-right: none;
}
</style>
