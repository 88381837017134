<template>
  <div>
    <template v-for="item in menuList">
      <template v-if="item.path">
        <el-menu-item :key="item.code" :index="item.path" :route="{ path: item.path, query: $route.query }">
          <span class="iconfont mr5" v-html="item.icon" v-if="item.icon"></span>
          <span>{{ item.name }}</span>
        </el-menu-item>
      </template>
      <!-- 有次级菜单的,则展开子选项-->
      <el-submenu v-else :key="item.code" :index="item.code">
        <template #title>
          <span class="iconfont mr5" v-html="item.icon" v-if="item.icon"></span>
          <span>{{ item.name }}</span>
        </template>
        <!-- 递归 -->
        <MenuTree :menuList="item.children"></MenuTree>
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  name: 'MenuTree',
  methods: {},
}
</script>
<style scoped lang="scss"></style>
