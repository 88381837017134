<template>
  <el-dialog fullscreen :visible="show" @close="close" :close-on-click-modal="false">
    <div slot="title"></div>
    <div class="flex">
      <el-tabs v-model="activeNoticeType" type="card" tab-position="left" @tab-click="handleTypeClick">
        <el-tab-pane v-for="(item, index) in noticeTypeList" :key="index" :label="item.dictionaryLabel" :name="item.dictionaryValue"></el-tab-pane>
      </el-tabs>
      <div class="flex-1 ml20">
        <div>
          <div class="flex-bc mb10 mr20">
            <el-radio-group v-model="readed" @input="handleTypeClick">
              <el-radio-button label="all">全部</el-radio-button>
              <el-radio-button :label="false">未读</el-radio-button>
              <el-radio-button :label="true">已读</el-radio-button>
            </el-radio-group>
            <el-button type="primary" plain @click="handelAllRead">全部已读</el-button>
          </div>
          <el-form inline>
            <el-form-item label="">
              <el-input v-model="form.title" placeholder="通知标题" clearable></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="form.appSchemeCodeName" placeholder="应用方案" clearable></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="form.env" clearable placeholder="环境">
                <el-option v-for="(item, index) in envList" :key="index" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker v-model="times" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getNoticeList">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="notice-list" v-loading="getNoticeing">
          <template v-if="noticeList && noticeList.length">
            <el-card shawdow="none" class="notice-item mb10" v-for="(item, index) in noticeList" :key="index">
              <div class="flex-bc">
                <span>
                  <el-tag class="mr10" v-if="!item.readed" @click.stop="handleReadNotice(item)">标为已读</el-tag>
                  <span class="notice-title">{{ item.title }}</span>
                </span>
                <span class="notice-time">{{ item.invokeAccountName }} {{ item.noticeTime }}</span>
              </div>
              <div class="notice-content item-content" v-html="item.content"></div>
              <div class="notice-footer">
                <span class="mr5" v-if="item.appSchemeCodeName">方案：{{ item.appSchemeCodeName }}</span>
                <span class="mr5" v-if="item.appSchemeCode">({{ item.appSchemeCode }})</span>
                <span v-if="item.env">{{ getEnvStr(item.env) }}</span>
                <span class="ml20" v-if="item.appCodeName">应用：{{ item.appCodeName }}</span>
                <span class="ml5" v-if="item.appCode">({{ item.appCode }})</span>
                <el-popover placement="left-start" title="通知内容详情" width="850" trigger="hover">
                  <pre class="notice-content conten-info" v-html="item.content"></pre>
                  <el-button type="text" class="float-right" slot="reference">详情</el-button>
                </el-popover>
              </div>
            </el-card>
          </template>
          <template v-else>
            <el-empty description="暂无数据"></el-empty>
          </template>
        </div>
        <div class="flex-bc">
          <span></span>
          <el-pagination class="mt10" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size" layout="total, prev, pager, next" :total="totalNotice"> </el-pagination>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
import { mapState } from 'vuex'
import dayjs from 'dayjs'
export default {
  props: {
    show: {
      default: () => false,
      type: Boolean,
    },
  },
  data() {
    return {
      noticeTypeList: [],
      activeNoticeType: 'all',
      readed: 'all',
      noticeList: [],
      totalNotice: 0,
      getNoticeing: false,
      page: {
        current: 1,
        size: 20,
      },
      form: {},
      times: [],
    }
  },

  computed: {
    ...mapState(['envList']),
  },
  watch: {
    show(val) {
      if (val) {
        this.getNoticeType()
        this.getNoticeList()
      } else {
      }
    },
  },
  methods: {
    getNoticeType() {
      const allItem = [{ dictionaryLabel: '全部', dictionaryValue: 'all' }]
      api.getDistTypeList({ dictionaryTypes: 'notice_model' }).then(({ data }) => {
        this.noticeTypeList = [...allItem, ...data.notice_model]
      })
    },
    getNoticeList() {
      this.getNoticeing = true
      const params = {
        ...this.page,
        ...this.form,
        readed: '',
      }
      if (this.times && this.times.length) {
        params.startTime = dayjs(this.times[0]).format('YYYY-MM-DD HH:mm:ss')
        params.endTime = dayjs(this.times[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.activeNoticeType != 'all') {
        params.model = this.activeNoticeType
      }
      if (this.readed != 'all') {
        params.readed = this.readed
      }
      if (!params.title) {
        delete params.title
      }
      if (!params.appSchemeCodeName) {
        delete params.appSchemeCodeName
      }
      if (!params.env) {
        delete params.env
      }
      api
        .getNotice(params)
        .then(({ data }) => {
          // 链接正则
          let reg = /(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi
          data.records?.forEach((item) => {
            item.content = item.content.replace(reg, function (ops) {
              return "<a href='" + ops + "' target='_blank'>" + ops + '</a>'
            })
          })
          this.noticeList = data.records
          this.totalNotice = data.total
        })
        .finally(() => {
          this.getNoticeing = false
        })
    },
    handleSizeChange(val) {
      this.page.current = 1
      this.page.size = val
      this.getNoticeList()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.getNoticeList()
    },
    handleTypeClick() {
      this.page = {
        current: 1,
        size: 20,
      }
      this.getNoticeList()
    },
    close() {
      this.$emit('update:show', false)
    },
    getEnvStr(env) {
      const label = this.envList.find((i) => i.value == env)?.label
      return '<' + label + '>'
    },
    handleReadNotice(item) {
      api.readNotice({ id: item.id }).then(() => {
        this.getNoticeList()
      })
    },
    handelAllRead() {
      api.readAll().then(() => {
        this.getNoticeList()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep a {
  color: #0366d6 !important;
  &:hover {
    text-decoration: underline;
  }
}
.conten-info {
  width: 100%;
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}
.notice-content {
  margin-top: 3px;
  font-size: 13px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.item-content {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*设置显示的行数*/
  line-clamp: 2; /*设置显示的行数*/
  -webkit-box-orient: vertical;
}
.notice-list {
  height: calc(100vh - 250px);
  overflow: auto;
  font-size: 14px;
  line-height: 25px;

  .notice-item {
    &:hover {
      background: #f5f5f5;
      .close-icon {
        display: inline-block;
      }
    }
    cursor: pointer;
    position: relative;

    .notice-title {
      color: $theme-color;
      font-weight: bold;
    }
    .notice-time {
      color: #a7a7a7;
      font-size: 12px;
    }
    .notice-footer {
      font-style: italic;
      color: #a2a2a2;
      font-size: 12px;
    }
  }
}
</style>
