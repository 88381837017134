<!-- 头部菜单栏 -->
<template>
  <div class="layout-header font14 flex-b">
    <div class="flex-c">
      <div
        @click="goHome"
        style="transition: width 0.25s; box-sizing: border-box; cursor: pointer"
        class="title"
        :class="{
          'title-default': !isFoldSlider,
          'title-active': isFoldSlider,
          'border-bottom-line': isHideLeftMenu & (curAppData2.code != '/micro-dev'),
          'border-right-none': isHideLeftMenu & (curAppData2.code != '/micro-dev'),
        }"
      >
        <img @click="goHome" src="@/assets/img/logo.png" style="width: 36px; height: 36px" :style="{ 'margin-right': isFoldSlider ? '0' : '8px' }" />
        <span v-show="!isFoldSlider">{{ systemName }}</span>
      </div>
    </div>

    <div class="flex-b border-bottom-line" style="width: 100%">
      <div class="flex-c">
        <div class="cursor fold-box flex-cc" @click="handleEvent('changeFold')" v-if="!isHideLeftMenu || curAppData2.code == '/micro-dev'">
          <i :class="isFoldSlider ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
        </div>
        <i class="el-icon-s-home mr10 text-theme cursor" style="font-size: 20px" @click="goHome" title="首页"></i>
        <!-- 选择系统 -->
        <el-dropdown @command="handleEvent('system', $event)">
          <div class="flex-c cursor">
            <img style="width: 28px; height: 28px; overflow: hidden; margin-right: 10px" :src="curAppData.icon" v-if="curAppData.icon" />
            <span style="font-weight: 700">{{ curAppData.name }}</span>
            <i class="el-icon-caret-bottom el-icon--right" style="color: #aaa; font-size: 16px"></i>
          </div>

          <el-dropdown-menu slot="dropdown" class="custom-el-dropdown-menu">
            <el-dropdown-item v-for="item in globalApp" :key="item.code" :command="item" :class="{ active: curAppData.id === item.id }" class="text-center">
              <div class="flex-c">
                <img style="width: 22px; height: 22px; overflow: hidden; margin-right: 10px" :src="item.icon" />
                <span style="font-weight: 700">{{ item.name }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="header-right flex">
        <!-- 菜单 -->
        <div class="menu-list mr20">
          <w-nav-mune />
        </div>
        <!--应用-->
        <w-app v-if="appStatus.isApp && appCodeQuery" />

        <div class="mr20 flex-center-item" @click="getNotice">
          <el-popover v-loading="getNoticeing" placement="bottom" width="400" trigger="click">
            <div class="flex-bc mb10">
              <span class="font12 font-bold">消息通知</span>
              <span>
                <el-button type="primary" plain @click="handleNoticeConfig">通知设置</el-button>
                <el-button type="primary" plain @click="handelAllRead">全部已读</el-button>
              </span>
            </div>
            <div class="notice-item font12" v-for="(item, index) in noticeList" :key="index" @click="handleNoticeDetail(item)">
              <div class="flex-bc">
                <span class="notice-title">{{ item.title }}</span>
                <span class="notice-time">{{ item.noticeTime }}</span>
              </div>
              <div class="notice-content">{{ item.content }}</div>
              <i class="close-icon el-icon-error" @click.stop="handleReadNotice(item)"></i>
            </div>
            <el-empty v-if="!noticeList.length" description="暂无未读消息" :image-size="100" class="font12"></el-empty>
            <div class="notice-all flex-cc mt10 pt10"><el-button type="text" @click="handleAllNotice">查看全部</el-button></div>
            <el-tooltip slot="reference" class="item" effect="dark" content="通知" placement="bottom">
              <el-badge :value="totalNotice">
                <img src="@/assets/img/message.png" class="layout-icon-img" />
              </el-badge>
            </el-tooltip>
          </el-popover>
        </div>

        <div class="mr20 flex-center-item">
          <el-tooltip class="item" effect="dark" content="帮助文档" placement="bottom">
            <img src="@/assets/img/help.png" class="layout-icon-img" @click="toHelp" />
          </el-tooltip>
        </div>
        <div class="mr20 flex-center-item">
          <w-collect />
        </div>

        <!--用户信息-->
        <div class="user-info mr20">
          <w-user-info :userInfo="userInfo" />
        </div>
      </div>
    </div>
    <noticeModal :show.sync="showNoticeModal" />
    <noticeConfigModal :show.sync="showNoticeConfigModal" />
  </div>
</template>

<script>
import WCollect from './components/collect/index.vue'
import WUserInfo from './components/userInfo'
import WApp from './components/app'
import WNavMune from './components/navMenu.vue'
import actions from '@/micro/actions'
import { mapGetters, mapState } from 'vuex'
import { getPath } from '@/utils'
import api from '@/api/index'
import noticeModal from './components/noticeModal.vue'
import noticeConfigModal from './components/noticeConfigModal.vue'

export default {
  components: {
    WNavMune,
    WUserInfo,
    WApp,
    WCollect,
    noticeModal,
    noticeConfigModal,
  },
  data() {
    return {
      systemName: process.env.VUE_APP_TITLE,
      totalNotice: 0,
      noticeList: [],
      getNoticeing: false,
      showNoticeModal: false,
      showNoticeConfigModal: false,
      ws: null,
      noticeToken: '',
      timeoutNum: null,
    }
  },
  computed: {
    ...mapGetters(['isFoldSlider', 'isHideLeftMenu', 'userInfo']),
    ...mapState(['curAppData', 'curAppData2', 'globalApp', 'appList', 'appStatus', 'appCodeQuery', 'allLocalApp', 'appschemeMenu', 'envList']),
  },
  watch: {
    userInfo: {
      handler(data) {
        actions.setGlobalState({ userInfo: data })
      },
      deep: true,
    },
    appList(data) {
      actions.setGlobalState({ appList: data })
    },
    allLocalApp(data) {
      actions.setGlobalState({ allLocalApp: data })
    },
    appschemeMenu(data) {
      actions.setGlobalState({ appschemeMenu: data })
    },
  },
  created() {
    this.getSocket()
    this.getNotice()
  },
  methods: {
    handleEvent(type, item) {
      switch (type) {
        case 'system':
          this.$store.commit('SET_LEFT_MENU_SHOW_STATUS', false)
          const path = getPath(item.children) || item.code
          if (item.code === '/cmp') {
            // 资源系统特殊处理
            window.location.href = window.location.origin + `${path}?p_id=${item.id}`
          } else {
            this.$router.push({ path, query: { p_id: item.id } })
          }
          this.$store.dispatch('GET_CUR_APP_INFO', this.appList)
          this.$store.commit('SET_CUR_APP2', {})
          this.$store.commit('SET_APPLICATION', {})
          break
        case 'changeFold':
          actions.setGlobalState({ isFoldSlider: !this.isFoldSlider })
          this.$store.commit('SET_FOLD_SLIDER_STATUS', !this.isFoldSlider)
          break
      }
    },
    // 显示帮助文档
    toHelp() {
      let pathArr = this.$route.path.split('/')
      if (pathArr.length > 1) {
        let systemCode = '/' + pathArr[1]
        window.open(`/helpDoc?code=${systemCode}`, '_blank')
      }
    },
    // 回到首页
    goHome() {
      // this.$router.push({ path:'/'});
      window.location.href = '/'
    },
    async getSocket() {
      const { data: token } = await api.getNoticeToken()
      this.noticeToken = token
      this.initWs()
    },
    initWs() {
      this.timeoutNum && clearTimeout(this.timeoutNum)
      let host = '',
        protocol = ''
      if (window.location.host == 'edt.mokihi.com') {
        host = 'edt.mokihi.com'
        protocol = 'ws'
      } else {
        host = 'edt-test.mokihi.com'
        protocol = 'ws'
      }
      const url = `${protocol}://${host}/api/edt-notice/notice/ws/${this.noticeToken}`
      this.ws = new WebSocket(url)
      this.ws.onclose = this.onclose
      this.ws.onerror = this.onerror
      this.ws.onmessage = ({ data }) => {
        try {
          let { title, content, appSchemeCodeName, appSchemeCode, env, appCodeName, appCode } = JSON.parse(data)
          const envStr = this.envList.find((i) => i.value == env)?.label
          // 链接正则
          let reg = /(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi
          // 解析链接
          content = content.replace(reg, function (ops) {
            return "<a href='" + ops + "' target='_blank' style='color: #0366d6'>" + ops + '</a>'
          })
          const h = this.$createElement
          this.$notify({
            title: title,
            message: h('p', { style: 'cursor: pointer' }, [h('pre', { style: 'color:#33bba7;font-style:italic;width:100%;width:280px;text-overflow: -o-ellipsis-lastline;overflow: hidden;white-space: pre-wrap;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 5; line-clamp: 5; -webkit-box-orient: vertical;', domProps: { innerHTML: content } }), appSchemeCodeName ? h('i', { style: 'color:#a2a2a2;font-size:12px' }, '方案：' + appSchemeCodeName) : null, appSchemeCode ? h('i', { style: 'color:#a2a2a2;font-size:12px' }, '（' + appSchemeCode + '）') : null, envStr ? h('i', { style: 'color:#a2a2a2;font-size:12px' }, '<' + envStr + '>') : null, appCodeName ? h('p', {}, [h('i', { style: 'color:#a2a2a2;font-size:12px' }, '应用：' + appCodeName + '  （' + appCode + '）')]) : null]),
            onClick: () => {
              this.showNoticeModal = true
            },
          })
        } catch (err) {
          // console.error(err)
        }
      }
    },
    onclose(e) {
      // console.log('websocket 断开: ', e)
      if (e.code == '3005') {
        return
      }
      //重连
      if (!window.location.host.includes('localhost')) {
        this.initWs()
      }
    },
    onerror(e) {
      // console.log('连接失败')
      this.ws?.close()
      let self = this
      //重连
      if (!window.location.host.includes('localhost')) {
        this.timeoutNum = setTimeout(() => {
          self.initWs()
        }, 1000000)
      }
    },

    getNotice() {
      this.getNoticeing = true
      api
        .getNotice({
          current: 1,
          size: 10,
          readed: false,
        })
        .then(({ data }) => {
          this.noticeList = data.records
          this.totalNotice = data.total
        })
        .finally(() => {
          this.getNoticeing = false
        })
    },
    handleReadNotice(item) {
      api.readNotice({ id: item.id }).then(() => {
        this.getNotice()
      })
    },
    handleNoticeDetail(item) {
      this.handleReadNotice(item)
      this.showNoticeModal = true
    },
    handleAllNotice() {
      this.showNoticeModal = true
    },
    handelAllRead() {
      api.readAll().then(() => {
        this.getNotice()
      })
    },
    handleNoticeConfig() {
      this.showNoticeConfigModal = true
    },
  },
  destroyed() {
    this.ws?.close('3005')
  },
}
</script>
<style lang="scss" scoped>
.custom-el-dropdown-menu {
  width: 100px;
  .el-dropdown-menu__item.active {
    color: $theme-color;
  }

  .el-dropdown-menu__item {
    line-height: 35px !important;
  }
}

.custom-el-dropdown-menu1 {
  width: 120px;
  .el-dropdown-menu__item.active {
    color: $theme-color;
  }

  .el-dropdown-menu__item {
    line-height: 35px !important;
  }
}

.layout-header {
  background: #fff;
  height: 50px;
  line-height: 50px;
  .title {
    height: 50px;
    line-height: 50px;
    white-space: nowrap;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1.2px;
    font-family: cursive;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #dedede;
  }

  .title-default {
    width: 210px;
  }

  .title-active {
    width: 64px;
    box-sizing: content-box;
  }

  .border-right-none {
    border-right: none;
  }
}
.el-dropdown {
  font-size: 13px;
}

.fold-box {
  width: 50px;
  height: 50px;
  color: #a3a3a3;
  font-size: 16px;
  border-right: 1px solid #e5e5e5;
  text-align: center;
  margin-right: 10px;
}

.flex-center-item {
  display: flex;
  align-items: center;
}

.el-badge {
  display: flex;
}

.layout-icon-img {
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  cursor: pointer;
  padding: 3px;
  border-radius: 3px;

  &:hover {
    background-color: #f0f0f0;
  }
}
.notice-item {
  &:hover {
    background: #f5f5f5;
    .close-icon {
      display: inline-block;
    }
  }
  padding: 5px;
  border-top: 1px solid #f2f2f2;
  cursor: pointer;
  position: relative;
  .notice-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: italic;
    margin-top: 3px;
  }
  .notice-title {
    color: $theme-color;
  }
  .notice-time {
    color: #a2a2a2;
  }
  .close-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 20px;
    color: darkgray;
    display: none;
  }
}
.notice-all {
  border-top: 1px solid #f2f2f2;
}
</style>
