<template>
  <div>
    <div class="all-center advice-btn" @click="handleAdvice" v-drag>
      <i class="iconfont" title="我要吐槽">&#xe627;</i>
    </div>
    <el-dialog title="功能吐槽与建议" :visible="showAdviceModal" width="800" @close="close">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="需求场景" prop="source">
          <el-input type="textarea" v-model="form.source" placeholder="请输入" :autosize="{ minRows: 4, maxRows: 8 }"></el-input>
        </el-form-item>
        <el-form-item label="改进建议" prop="desc">
          <el-input type="textarea" v-model="form.desc" placeholder="请输入" :autosize="{ minRows: 4, maxRows: 8 }"></el-input>
        </el-form-item>
        <el-form-item label="是否实名" prop="anonymous">
          <el-switch v-model="form.anonymous"> </el-switch>
        </el-form-item>
        <el-form-item label="上传图片">
          <el-upload class="common-upload" action="/api/tenant-user/file/upload" :data="uploadParams" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <el-image fit="cover" v-if="form.pics" :src="form.pics" class="common-upload-image" />
            <i v-else class="common-upload-icon el-icon-plus avatar-uploader-icon"></i>
            <div v-if="form.pics" class="delete-box" @click.stop="handleDelete">
              <i class="el-icon-close"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :loading="submiting" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from '@/api/index'
export default {
  data() {
    return {
      showAdviceModal: false,
      submiting: false,
      form: {
        anonymous: true,
      },
      rules: {
        source: [{ required: true, message: '请输入需求场景', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入改进建议', trigger: 'blur' }],
        anonymous: [{ required: true, message: '', trigger: 'blur' }],
      },
      uploadParams: {
        businessType: 'system_icon',
      },
      fileSize: 0,
      fileType: 'image',
    }
  },
  methods: {
    handleAdvice() {
      this.showAdviceModal = true
    },
    close() {
      this.showAdviceModal = false
      this.$refs.form.resetFields()
      this.$set(this.form, 'pics', '')
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            anonymous: this.form.anonymous,
            source: this.form.source,
            desc: this.form.desc,
            pics: this.form.pics ? [this.form.pics] : [],
            type: 5,
            useType: 1,
            status: 0,
          }
          this.submiting = true
          api
            .ticketAdd(params)
            .then(() => {
              this.$message.success('操作成功')
              this.close()
            })
            .finally(() => {
              this.submiting = false
            })
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.$set(this.form, 'pics', res.data.ossUrl)
    },
    beforeAvatarUpload(file) {
      let typeCheck = true,
        sizeCheck = true
      if (this.fileType) {
        typeCheck = file.type.indexOf(this.fileType) > -1
        if (!typeCheck) {
          this.$msg.error('文件格式错误')
        }
      }
      if (this.fileSize) {
        sizeCheck = file.size / 1024 / 1024 < Number(this.fileSize)
        if (!sizeCheck) {
          this.$msg.error(`文件不得大于${this.fileSize}M`)
        }
      }
      return typeCheck && sizeCheck
    },
    handleDelete() {
      this.$set(this.form, 'pics', '')
    },
  },
}
</script>
<style lang="scss">
.advice-btn {
  width: 32px;
  height: 32px;
  position: fixed;
  bottom: 100px;
  right: 60px;
  z-index: 999999;
  background: rgba(204, 204, 204, 0.5);
  border-radius: 50%;
  cursor: pointer;
  color: white;

  &:hover {
    background: #b5b5b5;
  }
  .iconfont {
    font-size: 16px;
  }
}
.common-upload {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
    }
    .delete-box {
      position: absolute;
      right: -17px;
      top: -7px;
      width: 46px;
      height: 26px;
      background: #f56c6c;
      text-align: center;
      transform: rotate(45deg);
      i {
        transform: rotate(-45deg);
        color: #fff;
        line-height: 35px;
        display: inline-block;
        font-size: 12px;
      }
    }
  }
  .common-upload-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
  }
  .common-upload-image {
    width: 120px;
    height: 120px;
    display: block;
  }
}
</style>
