<!-- 顶部菜单 -->
<template>
  <el-tabs class="app-cuntom-el-tabs" v-model="activeIndex" @tab-click="switchNemu">
    <el-tab-pane v-for="item in menuData" :label="item.name" :name="item.code" :key="item.code"> </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      activeIndex: '',
      menuData: [],
    }
  },
  computed: {
    ...mapState(['curAppData', 'curAppData2', 'appStatus', 'appList', 'application', 'allLocalApp']),
  },
  watch: {
    $route(val) {
      const { p_app_code } = val.query
      if (p_app_code) {
        this.$store.dispatch('GET_CUR_APP_INFO', this.allLocalApp[p_app_code])
      } else {
        this.$store.dispatch('GET_CUR_APP_INFO', this.appList)
      }
    },
    appStatus: {
      handler() {
        this.init()
      },
      deep: true,
    },
  },
  methods: {
    init() {
      if (this.appStatus.isApp && this.appStatus.isSelectApp) {
        this.menuData = this.curAppData2.children || []
      } else {
        this.menuData = this.curAppData.children || []
      }
      let sessionMenu = JSON.parse(sessionStorage.getItem('getLeftMenuData'))
      // 获取activeIndex及左侧菜单数据
      for (let item of this.menuData) {
        if (item.children) {
          this.getActiveIndex(item, this.$route.path, item.code, item.children)
        }
      }
      // 解决刷新浏览器后左侧菜单不显示问题
      if ((!this.activeIndex || this.activeIndex == '0') && sessionMenu?.menudData?.length) {
        if (this.$route.path.indexOf(sessionMenu?.curSystemCode)) {
          // 判断缓存的菜单是否是当前系统的
          this.$store.commit('SET_LEFT_MENU_DATA', sessionMenu.menudData)
        }
      }
    },
    /**
     * @description 根据路由获取activeIndex值及左侧菜单数据
     * @param data
     * @param path 当前路由路径
     * @param code 导航栏code
     * @param parentData 父级数据
     */
    getActiveIndex(data, path, code, parentData) {
      for (let item of data.children) {
        if (item.children?.length && item.type !== 'resource') {
          this.getActiveIndex(item, path, code, parentData)
        } else {
          if (item.code === path) {
            this.activeIndex = code
            this.$store.commit('SET_LEFT_MENU_DATA', parentData)
            return
          }
        }
      }
    },
    switchNemu() {
      let curNavData = _.find(this.menuData, ['code', this.activeIndex])
      this.$store.commit('SET_LEFT_MENU_DATA', curNavData.children)
      // 获取最后一级数据
      const getTail = (item) => (item.children && item.children.length > 0 ? item.children.map((m) => getTail(m)) : [item])
      const result = _.flattenDeep(curNavData.children.map((m) => getTail(m))) || []
      let path = result[0]?.code

      this.$router.push({
        path,
        query: {
          p_id: this.curAppData.id,
          c_id: this.curAppData2.id,
          p_app_code: this.application.appCode,
          appSchemeCode: this.$route.query.appSchemeCode
        },
      })
    },
  },
}
</script>
<style lang="scss">
.app-cuntom-el-tabs {
  .el-tabs__item {
    // font-size: 14px !important;
    // font-family: cursive;
    padding: 0px 8px;
    font-size: 13px !important;
  }

  .el-tabs__nav-wrap::after {
    background-color: transparent;
  }

  .el-tabs__header {
    margin-bottom: 0;
  }
}
</style>
