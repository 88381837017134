<template>
  <el-dialog title="通知配置" fullscreen :visible="show" @close="close" :close-on-click-modal="false">
    <div class="configList">
      <div class="mb10">
        <span class="font-bold mr20">全局开关配置</span>
        <el-radio-group v-model="globalOpen">
          <el-radio-button v-for="(typeItem, typeIndex) in typeList" :key="typeIndex" :label="typeItem.dictionaryValue">{{ typeItem.dictionaryLabel }}</el-radio-button>
        </el-radio-group>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item v-for="(item, index) in configList" :key="index" :name="item.modelName">
          <template slot="title">
            <div class="mt10">
              <span class="font-bold ml20 mr20">模块{{ index + 1 }}：{{ item.modelName }}</span>
              <el-radio-group v-model="item.mode">
                <el-radio-button v-for="(typeItem, typeIndex) in typeList" :key="typeIndex" :label="typeItem.dictionaryValue">{{ typeItem.dictionaryLabel }}</el-radio-button>
              </el-radio-group>
            </div>
          </template>
          <div class="pl20 ml20 mt10">
            <div class="ml20 pl20" v-for="(citem, cindex) in item.subs" :key="cindex">
              <div class="font13 common-header" style="margin-bottom: 5px !important">{{ citem.modelName }}</div>
              <div class="pb10 mb20" style="border-bottom: 1px solid #f1f1f1">
                <el-radio-group v-model="citem.mode">
                  <el-radio-button v-for="(typeItem, typeIndex) in typeList" :key="typeIndex" :label="typeItem.dictionaryValue">{{ typeItem.dictionaryLabel }}</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="flex-cc">
      <el-button type="primary" @click="handleSave" :loading="submiting">保存</el-button>
      <el-button type="primary" plain @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
import { mapState } from 'vuex'
export default {
  props: {
    show: {
      default: () => false,
      type: Boolean,
    },
  },
  data() {
    return {
      configList: [],
      globalOpen: '',
      typeList: [],
      submiting: false,
      activeNames: [],
    }
  },

  computed: {
    ...mapState(['envList']),
  },
  watch: {
    show(val) {
      if (val) {
        this.getConfig()
        this.getConfigType()
      }
    },
  },
  methods: {
    getConfig() {
      api.getNoticeConfig().then(({ data }) => {
        console.log({ data })
        this.configList = data.subConfigOpen
        this.globalOpen = data.globalOpen
        this.activeNames = this.configList.map((i) => i.modelName)
      })
    },
    getConfigType() {
      api.getDistTypeList({ dictionaryTypes: 'notice_setting_type' }).then(({ data }) => {
        console.log({ data })
        this.typeList = data.notice_setting_type
      })
    },
    handleSave() {
      const params = {
        globalOpen: this.globalOpen,
        subConfigOpen: this.configList,
      }
      this.submiting = true
      api
        .modifyNoticeConfig(params)
        .then(() => {
          this.$message.success('操作成功')
          this.close()
        })
        .finally(() => {
          this.submiting = false
        })
    },
    close() {
      this.$emit('update:show', false)
    },
  },
}
</script>
<style scoped lang="scss">
.configList {
  height: calc(100vh - 150px);
  overflow: auto;
}
</style>
